import React from 'react';
import LandingPageViewMay22 from 'views/LandingPageViewMay22';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Save on Azure Blob Storage Pricing';
const content = {

  hero: {
    title: "Tired of overpaying for cloud storage?",
    emphasized: "<span STYLE='color:black'><br>The average WindRate user<br>saves $10,500 a year<br><br></span>",
    subtitle: "Our no-cost, risk-free matching service finds the best cloud storage for your needs, at the best price. Most users cut their current bill in half.",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },

  providersInfo: {
    title: "Join hundreds of companies who have saved thousands of dollars on their cloud storage.",
    subtitle: "Including huge savings on AWS S3, Google Cloud Storage and Azure Blob Storage",
    providers: [
        {
            name: "AWS S3 Storage",
            logo: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
            id: "aws"
        },

        {
            name: "Google Cloud Storage (GCP)",
            logo: "https://upload.wikimedia.org/wikipedia/commons/0/01/Google-cloud-platform.svg",
            id: "google"
        },

        {
            name: "Microsoft Azure Storage",
            logo: "/images/Azure.svg",
            id: "azure"
        }
    ]
  },

  videoSection: {
    title: "Compare and Save on Cloud Storage Costs",
    subtitle: "WindRate is a new way to shop for cloud storage, its the same storage you use now but at incredible discounts",
    callToAction: {
        buttonText: "Get a Quote Now",
        href: "/get-a-quote"
    },

    videoFrame: {
        title: "video",
        src: "https://www.youtube.com/embed/j-tCtk4e6Ys?autoplay=1",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    }
    },

  quoteForm2: {
    quote_provider:"azure",
    title: "Save up to 60%. Just tell us how much storage you need, where you need it, and we'll do the rest.",  
    subtitle: "Already using Azure Blob Storage or another storage provider? We can find you wholesale discounts up to 60% on that same storage.",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "azure-s3-storage-save",   
    
    quote_skin: {
        quoteTitleLeft : " ",
        quoteTitleRight : "Start Saving Now",
        button_text: "Get Started For Free"
    },
    
  },

  quoteForm: {
    quote_provider:"azure",
    title: "Save 60% per TB on AWS S3 storage from leading providers",  
    subtitle: "Get the perfect match of providers from our AI and human curators",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "azure-s3-storage-save",

    quote_skin: {
        button_text: "Get Started For Free"
    }
  },

  subText: {
    title: "Same AWS S3 Storage",
    emphasized: "but cheaper",
    subtitle: "Get great pricing on Google Gloud Standard, Coldline, Nearline, Archive storage.<br><br>Compare GCP vs AWS and GCP vs Azure in a single click.<br><br>We will take your requirements and source competitive quotes from leading partners and Managed Service Providers, saving you up to 60% on the same AWS S3 storage you use today. You decide who to deal with and when, your information stays anonymous in the meantime.<br>",
    subtitleEmphasized: "Request a quote today, it’s simple and free!",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  },    

  subText2: {
    title: "Start the competitive bidding process on your AWS S3 storage.",
    emphasized: "<br>It’s free and fast.<br><br>WindRate helps you deliver",
    subtitle: "<div style='display: flex; flex-direction: column;'><div>Compare. Multiple competitive vendor quotes.</div><div>Contact. You initiate the contact only if you like the price.</div><div>Succeed. A custom pricing summary PDF for your team.</div></div>",
    subtitleEmphasized: undefined,
    titleVariant: "h5",
    subtitleVariant: "h6",     
  },    

};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageViewMay22}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 